.home {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro {
    max-width: 540px;
    text-align: center;
}

.home_img {
    margin-bottom: 1.5rem;
}

.home_name {
    font-size: var(--h1-font-size);
    font-weight: var(--font-bold);
    margin-bottom: 0.5rem;
}

.home_socials {
    display: flex;
    column-gap: 1.75rem;
    margin: 1.5rem 0;
    align-items: center;
    justify-content: center;
}

.home_social-link {
    color: var(--title-color);
    font-size: 1.3rem;
    transition: .3s;
}

.home_social-link:hover {
    color: hsl(43, 100%, 68%);
}

.scroll_down {
    position: absolute;
    bottom: 2.5rem;
    left: 0;
    width: 100%;
}

.home_scroll-name {
    font-size: var(--small-font-size);
}

.mouse {
    border: 2px solid #454360;
    display: block;
    height: 1.6rem;
    width: 1.25rem;
    margin: auto;
    margin-top: .75rem;
    border-radius: 1rem;
    position: relative;
}

@keyframes ani-mouse {
    0% { 
        top: 29%;
    }
    15% {
        top: 50%;
    }
    50% {
        top: 50%;
    }
    100% {
        top: 29%;
    }
}

.wheel {
    background-color: var(--title-color);
    border-radius: 100%;
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    top: .5rem;
    left: 50%;
    transform: translateX(-50%);
    animation: ani-mouse 2s linear infinite;
}

@media screen and (max-width: 768px) {
    .home {
      text-align: center;
    }
  
    .intro {
      max-width: 100%; 
      margin: 0 1rem;
    }
  
    .home_img {
      margin-bottom: 1rem;
    }
  
    .home_name {
      font-size: 1.75rem; 
    }
  
    .home_socials {
      margin: 1rem 0;
    }
  
    .home_social-link {
      font-size: 1rem; 
    }
  
    .scroll_down {
      bottom: 1.5rem; 
    }
  
    .home_scroll-name {
      font-size: 0.75rem; 
    }
  
    .mouse {
      height: 1rem; 
      width: 1rem; 
      margin-top: 0.5rem; 
    }
  
    .wheel {
      top: 0.25rem; 
      width: 0.125rem; 
      height: 0.125rem;
    }
  
    @keyframes ani-mouse {
      0%, 100% {
          top: 15%;
      }
      50% {
          top: 50%;
      }
    }
  }
  