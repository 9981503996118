.work_filters {
  display: flex;
  align-items: center;
  column-gap: 1.875rem;
  margin-bottom: 2.5rem;
}

.work_item {
  cursor: pointer;
  font-weight: var(--font-bold);
}

.work_item:hover {
  color: var(--first-color);
}

.work_img {
  width: 250px;
  height: 250px;
  margin-right: 0.625rem;
}

.work_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.work_container {
  display: flex;
  gap: 1rem;
  overflow: hidden;
  max-width: 950px;
  transition: transform 0.3s ease-in-out;
}

.work_card {
  flex: 0 0 calc(100% / 3 - 1rem);
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  text-align: center;
  position: relative;
}


.arrow {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  border-radius: 10%;
}

.arrow.left {
  left: -40px;
}

.arrow.right {
  right: -40px;
}

.arrow:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.work_mask {
  background-color: #6c6ce5;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: .3s;
  opacity: 0;
}

.work_card:hover .work_mask {
  opacity: 0.9;
}

.work_category {
  color: #fff;
  background-color: var(--first-color);
  border-bottom-left-radius: 0.98rem;
  border-bottom-right-radius: 0.98rem;
  position: absolute;
  top: 0;
  left: 1.5rem;
  font-size: var(--small-font-size);
  display: inline-block;
  padding: 0.19rem 0.625rem;
  transform: translateY(-40px);
  transition: .3s;
}

.work_title {
  color: #fff;
  font-size: var(--h3-font-size);
  margin: 0 0 0.98rem;
  padding: 0 1.25rem;
  position: absolute;
  top: 1.25rem;
  transform: translateY(30px);
  transition: .3s;
  opacity: 0;
}

.work_button {
  color: #fff;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  font-size: var(--h3-font-size);
  display: block;
  background-color: #ffd15c;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  transition: .3s;
  opacity: 0;
}

.work_card:hover .work_button {
  opacity: 1;
}

.work_card:hover .work_title, 
.work_card:hover .work_description {
  opacity: 1;
  transform: translateY(0);
}

.work_description {
  display: block;
  padding-left: 0.875rem;
  padding-right: 0.675rem;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .work_filters {
    flex-direction: column;
    align-items: flex-start;
    column-gap: 0;
    margin-bottom: 1.5rem;
  }

  .work_button {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      line-height: 30px;
    }

  .work_item {
    margin-bottom: 0.5rem;
  }

  .work_container {
    grid-template-columns: 1fr; 
    gap: 1rem;
    overflow-x: auto; 
    scroll-snap-type: x mandatory;
    white-space: nowrap;
  }

  .work_card {
    width: 100%;
    margin-bottom: 10px;
    display: inline-block;
    scroll-snap-align: start;
  }

  .work_mask,
  .work_category,
  .work_title,
  .work_description {
    width: 100%; 
  }

  .work_title,
.work_button {
  opacity: 0; 
  transform: translateY(30px);
  transition: .3s; 
}

.work_card:hover .work_title,
.work_card:hover .work_button {
  opacity: 1; 
  transform: translateY(0); 
}

  .work_category,
  .work_title {
    left: 0;
  }

}
:root {
  --text-color: #333;
}
@media (prefers-color-scheme: dark) {
  :root {
    --text-color: #fff;}
  .work_title, 
  .work_category, 
  .work_description, 
  .work_button {
    color: var(--text-color) !important; 
  }

  .work_mask {
    background-color: rgba(108, 108, 229, 0.8); 
  }

  .work_card {
    background-color: #222; 
  }
}