.nav_logo {
  width: 2.75rem;
  height: 2.75rem;
}
.aside {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--body-color);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 2.5rem;
    width: 110px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    align-items: center;
    background: var(--body-color);
}

@media screen and (max-width: 768px) {
  .aside {
    width: 54px;
    padding: 1rem;
    position: fixed;
    left: 0;
    top: 0;
    background: var(--body-color);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
  }

  .main {
    margin-left: 50px;
  }

  .nav_list {
    flex-direction: column;
    row-gap: 1rem;
  }

  .nav_link {
    font-size: 1rem;
  }

  .aside:hover {
    width: 90px;
  }
}

.nav_list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.nav_link {
    font-size: 1.5rem;
    color: var(--title-color);
    font-weight: var(--font-bold);
    transition: .3s;
}

.nav_link:hover {
    color: hsl(43, 100%, 68%);
}

.copyright {
    color: hsl(245, 15%, 65%);
    font-size: var(--small-font-size);
    transform: rotate(-180deg);
    align-items: left;
}

body {
  background-color: #ffffff;
  color: #000000;
  transition: all 0.3s ease-in-out;
}


  /* @media screen and (max-width: 768px) {
    .aside {
      width: 30px;
      min-height: 20px;
      padding: 0.2rem;
      position: static;
      border-right: none;
      position: fixed;
        left: 0;
        top: 0;
        background: var(--body-color);
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 10;
    }
  
    .main {
      margin-left: 0;
    }
  
    .nav_list {
      flex-direction: row; 
    }
    
    .nav_link {
      font-size: 1rem;
    }

  } */

