.about_container {
    grid-template-columns: 3fr 9fr;
    column-gap: 1.875rem;
}

.about_data {
    padding: 1.875rem;
    background-color: var(--container-color);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.875rem;
    align-items: flex-start;
    position: relative;
}

.about_desc {
    margin-bottom: 1rem;
    text-align: justify;
}

.about_skills {
    row-gap: 1.25rem;
}

.skills_titles {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.skills_name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.skills_number {
    line-height: 1.2;
}

/* .skills_bar,
.skills_percentage {
    height: 7px;
    border-radius: 0.25rem;
}

.skills_bar {
    background-color: #f1f1f1;
}

.skills_percentage {
    display: block;
    background-color: var(--first-color);
}

.development {
    width: 85%;
    background-color: rgb(193, 255, 92);
}

.problem {
    width: 95%;
    background-color: rgb(111, 255, 92);
}

.learning {
    width: 95%;
    background-color: rgb(111, 255, 92);
}

.python {
    width: 85%;
    background-color: rgb(193, 255, 92);
}

.sharp {
    width: 90%;
    background-color: rgb(111, 255, 92);
}

.react {
    width: 80%;
    background-color: rgb(193, 255, 92);
}

.html {
    width: 90%;
    background-color: rgb(111, 255, 92);
} */

@media screen and (max-width: 768px) {
    .about_container {
      grid-template-columns: 1fr; 
      column-gap: 1rem;
    }
    
    .btn {
        margin-bottom: 20px;
    }
  
    .about_data {
      padding: 1.5rem; 
      grid-template-columns: 1fr; 
      column-gap: 1rem;
    }
  
    .skills_titles {
      flex-direction: column;
      margin-bottom: 0.5rem;
    }
  
    .skills_name {
      margin-bottom: 0.5rem;
    }
  
    /* .skills_percentage,
    .skills_bar {
      height: 5px;
    }
  
    .development {
        width: 75%;
        background-color: rgb(247, 255, 92);
    }
    
    .problem {
        width: 85%;
        background-color: rgb(209, 255, 92);
    }
    
    .learning {
        width: 100%;
        background-color: rgb(119, 255, 92);
    }
    
    .python {
        width: 85%;
        background-color: rgb(209, 255, 92);
    }
    
    .sharp {
        width: 70%;
        background-color: rgb(255, 209, 92);
    }
    
    .react {
        width: 70%;
        background-color: rgb(255, 209, 92);
    }
    
    .html {
        width: 80%;
        background-color: rgb(225, 255, 92);
    } */
  }
  