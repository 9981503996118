.resume_container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.875rem;
}

.timeline {
    background-color: var(--container-color);
    padding: 1.875rem;
    border: var(--border-radius);
    position: relative;
    box-shadow: var(--shadow);
}

.timeline_item {
    position: relative;
    padding-left: 3.125rem;
    padding-bottom: 3.125rem;
}

.timeline_item:last-child {
    padding-bottom: 0;
}

.timeline_item:before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: var(--first-color);
    position: absolute;
    left: .25rem;
    top: 0;
}

.timeline .icon_briefcase,
.timeline .icon-graduation {
    position: absolute;
    left: -0.4375rem;
    top: 0;
    font-size: var(--h2-font-size);
    color: var(--first-color);
    background-color: var(--container-color);
    padding: 0.4375rem 0;
}

.timeline_date {
    color: #8b88b1;
    font-size: var(--small-font-size);
}

.timeline_title {
    font-size: var(--h3-font-size);
    margin: 0.5rem 0;
}

@media screen and (max-width: 768px) {
    .resume_container {
      grid-template-columns: 1fr; 
      column-gap: 0;
    }
  
    .timeline {
      padding: 1.5rem; 
    }
  
    .timeline_item {
      padding-left: 2rem; 
      padding-bottom: 2rem; 
    }
  
    .timeline_item:before {
      left: 1rem; 
    }
  
    .timeline .icon_briefcase,
    .timeline .icon-graduation {
      font-size: 1.5rem; 
      padding: 0.3rem 0; 
    }
  
    .timeline_date {
      font-size: var(--small-font-size); 
    }
  
    .timeline_title {
      font-size: var(--h3-font-size); 
      margin: 0.25rem 0; 
    }
  }
  
