/* 
  1) Light Mode (oletus) 
  ----------------------
  Aseta :root-tasolla samat muuttujat, joita käytät kaikkialla.
*/
:root {
  /* Jo index.css:ssä olleet muuttujat: */
  --body-color: hsl(258, 60%, 98%);   /* Sivun tausta */
  --title-color: hsl(244, 24%, 26%);  /* Otsikot, esim. h1,h2,h3 */
  --text-color: hsl(244, 16%, 43%);   /* Yleinen teksti */
  --container-color: #fff;            /* Korttien / osioiden tausta */
  --first-color: hsl(353, 100%, 65%); /* Nappien tms. tehosteväri */

  /* Fonttikoot, font-family jne. */
  --body-font: 'Rubik', sans-serif;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;

  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  --shadow: 0px 5px 20px 0px rgb(69 67 96 / 10%);
  --border-radius: 20px;
}

/* 
  2) Perustyylit light-tilassa 
  ---------------------------
*/
html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--text-color); /* Yleinen tekstiväri */
}

body {
  background-color: var(--body-color);
  line-height: 1.7;
  transition: all 0.3s ease-in-out;
}

/* Yleisotsikot */
h1, h2, h3 {
  color: var(--title-color);
  font-weight: var(--font-semibold);
  line-height: 1.2;
}

ul {
  list-style: none;
}
a {
  text-decoration: none;
}
button {
  cursor: pointer;
  border: none;
  outline: none;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.container {
  max-width: 1080px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}
.grid {
  display: grid;
}

/* .section, .section_title (kuten index.css:ssä) */
.section {
  padding-top: 7rem;
  padding-bottom: 2rem;
}
.section_title {
  font-size: var(--h1-font-size);
  margin-left: 0.875rem;
  font-weight: var(--font-bold);
  position: relative;
  margin-bottom: 3.75rem;
  color: var(--title-color); /* varmistetaan */
}
.section_title::before {
  content: '';
  height: 2.25rem;
  width: 2.25rem;
  position: absolute;
  left: -0.875rem;
  top: -0.875rem;
}

/* Nappi (btn) */
.btn {
  padding: 0.75rem 2rem;
  line-height: 1;
  border-radius: 1.875rem;
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  border: 1px solid transparent;
  color: #fff;
  background-color: var(--first-color);
  font-weight: var(--font-bold);
}
.btn:hover {
  animation: button-push 0.3s linear 1;
}
@keyframes button-push {
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

/* .main, .aside, .nav_link, jne. */
.main {
  margin-left: 110px;
}
.aside {
  background-color: var(--container-color);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.nav_link {
  color: var(--text-color); 
}
.nav_link i {
  color: var(--text-color);
}
.nav_link:hover {
  color: hsl(43, 100%, 68%);
}

/* Kotisivun home-luokat (light mode) */
.home_name,
.home_education {
  /* Käytetään otsikkovärejä tai tekstivärejä tarpeen mukaan */
  color: var(--title-color);
}
.home_social-link {
  color: var(--title-color);
}
.home_social-link:hover {
  color: hsl(43, 100%, 68%);
}
.home_social-link i {
  color: var(--title-color);
}

/* .about_data, .work_card, .work_mask, .timeline, .experience_text, jne. */
.about_data,
.work_card,
.work_mask,
.timeline,
.experience_text,
.timeline_date,
.timeline_title,
.about_text {
  background-color: var(--container-color);
  color: var(--text-color);
}

/* 
  3) Dark Mode (manuaalinen) 
  --------------------------
  Kun body:lle lisätään luokka "dark-mode",
  nämä muuttujat ylikirjoittavat light-tilan arvot.
*/
body.dark-mode {
  --body-color: #1e1e1e;
  --title-color: #ffffff;
  --text-color: rgba(255, 255, 255, 0.8);
  --container-color: #2b2b2b;
  /* Jos haluat muuttaa --first-color (nappien punainen) pimeässä, lisää se tähän */
  
  background-color: var(--body-color);
  color: var(--text-color);
}

/* Sivupalkki (Dark Mode) */
body.dark-mode .aside {
  background-color: var(--container-color);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
/* Linkit ja ikonit (Dark Mode) */
body.dark-mode .nav_link {
  color: var(--text-color);
}
body.dark-mode .nav_link i {
  color: var(--text-color);
}
body.dark-mode .nav_link:hover {
  color: hsl(43, 100%, 68%);
}

/* Otsikot ja home-luokat (Dark Mode) */
body.dark-mode h1, 
body.dark-mode h2, 
body.dark-mode h3 {
  color: var(--title-color);
}
body.dark-mode .home_name,
body.dark-mode .home_education,
body.dark-mode .home_social-link,
body.dark-mode .home_social-link i {
  color: var(--title-color);
}

/* Kortit ja osiot (Dark Mode) */
body.dark-mode .about_data,
body.dark-mode .work_card,
body.dark-mode .work_mask,
body.dark-mode .timeline,
body.dark-mode .experience_text,
body.dark-mode .timeline_date,
body.dark-mode .timeline_title,
body.dark-mode .about_text {
  background-color: var(--container-color);
  color: var(--text-color);
}

/* Nappi (Dark Mode) */
body.dark-mode .btn {
  /* Jos haluat muuttaa napin taustaa tummassa, tee se tässä */
  /* color, background, jne. */
}

/* Theme-toggle -nappi */
.theme-toggle {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  background-color: #f8f8f885;
  border-radius: 5px;
  font-size: 16px;
}
.theme-toggle:hover {
  background-color: #0056b3;
}
body.dark-mode .theme-toggle {
  background-color: #0056b3;
}
body.dark-mode .theme-toggle:hover {
  background-color: #003f7f;
}

.dark-mode {
  background-color: #121212;
  color: white;
}

/* 
  4) Dark Mode – automaattinen kytkentä (prefers-color-scheme) 
  ------------------------------------------------------------
  Jos käyttäjän selain/käyttöjärjestelmä on tummassa tilassa,
  nämä arvot ylikirjoitetaan automaattisesti. 
*/
@media (prefers-color-scheme: dark) {
  :root {
    --body-color: #1e1e1e;
    --title-color: #ffffff;
    --text-color: rgba(255, 255, 255, 0.8);
    --container-color: #2b2b2b;
    /* --first-color: ... jos haluat muuttaa senkin */
  }
}

