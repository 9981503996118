.services_container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.875rem;
}

.services_card {
    text-align: center;
    padding: 1.875rem;
    border-radius: var(--border-radius);
    background-color: var(--container-color);
    transition: all 0.3s ease-in-out;
}

.services_card:hover {
    transform: translateY(-10px);
}

.services_card:nth-child(1) {
    background-color: rgb(108, 108, 229);
    box-shadow: 0px 5px 20px 0px rgb(108, 108, 229 / 50%);
}

.services_card:nth-child(2) {
    background-color: rgb(218, 150, 87);
    box-shadow: 0px 5px 20px 0px rgb(249, 215, 76 / 50%);
}

.services_card:nth-child(3) {
    background-color: rgb(249, 123, 139);
    box-shadow: 0px 5px 20px 0px rgb(249, 123, 139 / 50%);
}

.services_img {
    margin-bottom: 1.25rem;
}

.services_title {
    font-size: var(--h3-font-size);
    margin-bottom: 1rem;
    color: #fff;
}

.services_description {
    color: #f8f9fa;
}

.services_card:nth-child(2) .services_title {
    color: var(--title-color);
}

.services_card:nth-child(2) .services_description {
    color: var(--text-color);
}

@media screen and (max-width: 768px) {
    .services_container {
      grid-template-columns: 1fr;
      column-gap: 1rem;
    }
  
    .services_card {
      padding: 1.5rem; 
      margin-bottom: 20px;
    }
  
    .services_card:hover {
      transform: none;
    }
  
    .services_card:nth-child(1),
    .services_card:nth-child(2),
    .services_card:nth-child(3) {
      box-shadow: none; 
    }
  
    .services_title {
      font-size: var(--h2-font-size);
      margin-bottom: 0.75rem; 
    }
  
    .services_description {
      font-size: var(--normal-font-size);
    }
  }
  